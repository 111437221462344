<template>
    <div>
        <div class="product">

            <product-display page="orderPage" size="regular" :product="product" :buttonText="product.freebie? 'kostenlos Teilnehmen' : product.price + ' Euro'" :displaySignupForm="displaySignupForm" :videoIsLoading="videoIsLoading" :videoIsPlaying="videoIsPlaying"></product-display>

            <div class="product-testimonials">
                <div class="row">
                    <div class="gr-24">

                        <h2 class="product-testimonials-headline gr-16@tablet gr-22@mobile push-6 push-6@tablet push-1@mobile">Feedback von Teilnehmer:innen</h2>
                        <div class="product-testimonials-container gr-21 gr-18@tablet gr-22@mobile push-2 push-3@tablet push-1@mobile">

                            <div v-for="(testimonial, index) in product.testimonials" :key="index" class="product-testimonials-container-testimonial gr-8 gr-24@tablet">
                                <p class="product-testimonials-container-testimonial-quote">
                                    {{ testimonial.quote }}
                                </p>
                                <h5 class="product-testimonials-container-testimonial-signature">
                                    {{ testimonial.signature }}
                                </h5>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="product-challenge" v-if="product.type === 'challenge'">
                <div class="row">
                    <div class="gr-24">
                        <div class="row">
                            <div class="gr-24">
                                <h2 class="product-challenge-headline gr-9 gr-19@tablet gr-22@mobile push-6 push-2@tablet push-1@mobile">Lerne von Experten, wie du ein starkes Mindset für deine persönlichen Ziele erschaffen kannst.</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="gr-24">
                                <div class="product-challenge-instructions product-challenge-instructions-top gr-9 gr-18@tablet gr-20@mobile push-6 push-3@tablet push-2@mobile">
                                    <h5 class="product-challenge-instructions-subheadline">So einfach geht’s</h5>
                                    <p class="product-challenge-instructions-text">
                                        Nach der Anmeldung erhältst du ein kurzes Video mit den ersten Schritten zur App und dem Zugang zur Challenge.
                                        In wenigen Minuten bist du startklar.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="gr-24">
                                <div class="product-challenge-steps gr-18 gr-20@small gr-20@mobile push-3 push-2@small push-2@mobile">
                                    <div class="product-challenge-steps-step product-challenge-steps-step-one"><span class="product-challenge-steps-step-number">1</span>Anmelden</div>
                                    <div class="product-challenge-steps-step product-challenge-steps-step-two"><span class="product-challenge-steps-step-number">2</span>App installieren</div>
                                    <div class="product-challenge-steps-step product-challenge-steps-step-three"><span class="product-challenge-steps-step-number">3</span>Challenge starten</div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="gr-24">
                                <div class="product-challenge-instructions product-challenge-instructions-bottom gr-9 gr-18@tablet gr-20@mobile push-6 push-3@tablet push-2@mobile">
                                    <h5 class="product-challenge-instructions-subheadline">Was du dafür brauchst</h5>
                                    <div class="product-challenge-instructions-text gr-24">
                                        <div class="product-challenge-instructions-text-icon product-challenge-instructions-text-icon-one"></div>
                                        <p>Alle Inhalte und Aufgaben der Challenge kannst du über dein Smartphone per App aufrufen.</p>
                                    </div>
                                    <div class="product-challenge-instructions-text gr-24">
                                        <div class="product-challenge-instructions-text-icon product-challenge-instructions-text-icon-two"></div>
                                        <p>Für die Aufgaben wirst du 7 Minuten am Tag benötigen. Darüber hinaus steht dir das Üben frei.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="gr-24">
                                <a href="#signup" class="gr-4 push-6 gr-18@tablet gr-24@mobile push-3@tablet push-13@mobile"><button class="product-challenge-button button">Let's go!</button></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="product-signup" id="signup">
                <div class="product-signup-container row">
                    <div class="gr-24">
                        <form class="product-signup-form gr-14 gr-18@small gr-20@tablet gr-22@mobile push-5 push-3@small push-2@tablet push-1@mobile" @submit.prevent="validateFormData">

                            <table v-if="displaySignupForm">
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <h3>Zur Challenge anmelden:</h3>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            <label for="first-name">Vorname</label>
                                        </th>
                                        <td>
                                            <input type="text" id="first-name" name="firstName" :value="user? user.name.split(' ')[0] : userData.firstName" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="userDataValidity.firstName == false">Bitte gib einen gültigen Vornamen an.</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="last-name">Nachname</label>
                                        </th>
                                        <td>
                                            <input type="text" id="last-name" name="lastName" :value="user? user.name.split(' ')[1] : userData.lastName" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="userDataValidity.lastName == false">Bitte gib einen gültigen Nachnamen an.</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="email">E-Mail</label>
                                        </th>
                                        <td>
                                            <input type="text" id="email" name="email" :value="user? user.email : userData.email" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="userDataValidity.email == false">Bitte gib eine gültige Mailadresse an.</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <label for="phone">Telefon</label>
                                        </th>
                                        <td>
                                            <input type="text" id="phone" name="phone" :value="user? user.phone : userData.phone" @input="setFormData">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td class="error" v-if="userDataValidity.phone == false">Bitte gib eine gültige Telefonnummer an.</td>
                                    </tr>

                                    <tr class="checkbox-row">
                                        <td></td>
                                        <td>
                                            <div class="checkbox">
                                                <input type="checkbox" id="privacy" name="privacy" v-model="userData.privacy">
                                                <label for="privacy" :class="{ 'error': userDataValidity.privacy === false}">Ich bin mit dem <a @click="openPrivacyPage">Datenschutz</a> einverstanden.</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="checkbox-row">
                                        <td></td>
                                        <td>
                                            <div class="checkbox">
                                                <input type="checkbox" id="newsletter" name="newsletter" v-model="userData.newsletter">
                                                <label for="privacy">Ich möchte euren Newsletter abonnieren.</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="2">
                                            <button type="submit" class="order-button button">TEILNEHMEN & STARTEN</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                            <table v-else>
                                <thead>
                                    <tr>
                                        <td>
                                            <h3 class="success">Vielen Dank für deine Anmeldung.</h3>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="closed-form-text">
                                            Wir freuen uns auf dich und senden dir in Kürze den Zugang zur Challenge.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>

            <!--div class="product-news" v-if="product.news">
                <div class="row">
                    <div class="gr-24">
                        <div class="product-news-video gr-17 gr-20@small push-4 push-2@small">
                            <vimeo-player :ref="product.slug + '-news'" :video-id="product.news" controls=false @playing="setVideoLoading(false, product.slug + '-news')" @pause="setVideoLoading(false, product.slug + '-news')" @ended="toggleVideo(product.slug + '-news', $refs[product.slug + '-news'])"></vimeo-player>

                            <div class="loading-page" v-if="videoIsLoading[product.slug + '-news']">
                                <base-spinner class="spinner"></base-spinner>
                            </div>
                        </div>

                        <div class="product-news-video-button" @click="toggleVideo(product.slug + '-news', $refs[product.slug + '-news'])">
                            <img src="../images/play_button.png" alt="Play" v-if="!videoIsPlaying[product.slug + '-news']">
                            <img src="../images/pause_button.png" alt="Stop" v-else>
                        </div>

                        <transition name="fade">
                            <div v-show="!videoIsPlaying[product.slug + '-news']" class="product-news-video-button" @click="toggleVideo(product.slug + '-news', $refs[product.slug + '-news'])">
                                <img src="../images/play_button.png" alt="Play">
                            </div>
                        </transition>

                        <transition name="fade">
                            <div v-show="videoIsPlaying[product.slug + '-news']" class="product-display-video-button-tablet" @click="toggleVideo(product.slug + '-news', $refs[product.slug + '-news'])">
                                <img src="../images/pause_button.png" alt="Stop">
                            </div>
                        </transition>

                    </div>
                </div>
            </div-->

            <div class="product-podcasts">
                <div class="row">
                    <div class="gr-24">
                        <div class="product-podcasts-container gr-21 gr-22@tablet gr-24@mobile push-2 push-1@tablet push-0@mobile">
                            <h2 class="gr-22@mobile push-1 push-0@tablet push-1@mobile">Podcasts zum Thema Vertrieb</h2>
                            <div class="product-podcasts-container-videos gr-24@mobile">
                                <div class="row">
                                    <div v-for="(podcast, index) in this.product.podcasts" :key="index" class="stream gr-8 gr-22@mobile push-1@mobile">
                                        <iframe :src="'https://open.spotify.com/embed/episode/' + podcast +  '?utm_source=generator&theme=0'" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['slug', 'products', 'subscriptionCompleted', 'videoIsLoading', 'videoIsPlaying', 'displaySignupForm'],
        inject: ['setMetaData', 'setVideoLoading', 'toggleVideo', 'handleOrderProcess', 'sendNewsletterForm', 'toggleSignupForm'],
        data() {
            return {
                isLoading: false,
                userData: {},
                userDataValidity: {},
            }
        },
        created() {
            this.setMetaData({
                title: this.product.title + ' * Starkes Mindset',
                ogTitle: this.product.title + ' * Starkes Mindset',
                description: this.product.specification,
                ogDescription: this.product.specification,
            });
        },
        methods: {
            async openPrivacyPage() {
                let link = this.$router.resolve({ name: 'privacy' });
                window.open(link.href, '_blank');
            },
            async setFormData(event) {
                this.userData[event.target.name] = event.target.value;
            },
            async validateFormData() {
                if(this.userData.firstName == null || this.userData.firstName == '' || this.userData.firstName.length < 2) {
                    this.userDataValidity.firstName = false;
                } else {
                    this.userDataValidity.firstName = true;
                }

                if(this.userData.lastName == null || this.userData.lastName == '' || this.userData.lastName.length < 2) {
                    this.userDataValidity.lastName = false;
                } else {
                    this.userDataValidity.lastName = true;
                }

                if(this.userData.email == null || this.userData.email == '' || !this.userData.email.includes('@') || !this.userData.email.includes('.')) {
                    this.userDataValidity.email = false;
                } else {
                    this.userDataValidity.email = true;
                }

                if(this.userData.phone == null || this.userData.phone == '' || this.userData.phone.length < 8 || !/[0-9]/.test(this.userData.phone)) {
                    this.userDataValidity.phone = false;
                } else {
                    this.userDataValidity.phone = true;
                }

                if(this.userDataValidity.firstName && this.userDataValidity.lastName && this.userDataValidity.email && this.userDataValidity.phone) {

                    if(!this.userData.privacy) {
                        this.userDataValidity.privacy = false;
                    } else {
                        this.userDataValidity.privacy = true;
                        this.submit();
                    }
                }
            },
            async submit() {
                const user = this.userData;

                await this.handleOrderProcess(this.product, user);

                if(user.newsletter) {
                    await this.sendNewsletterForm(user.email, user);
                }

                this.toggleSignupForm(false);
            },
            openProductLink(link) {
                window.open(link, "_blank");
            }
        },
        computed: {
            product() {
                let product;
                for(let key in this.products) {
                    const singleProduct = this.products[key];
                    if(singleProduct.slug === this.slug) {
                        product = singleProduct;
                    }
                }
                return product;
            },
        }
    }
</script>

<style scoped>
</style>
