<template>
    <header>

        <div class="header">
            <div class="row">
                <div class="gr-24">
                    <div class="header-logo gr-9 gr-14@small gr-24@mini">
                        <div class="row">
                            <router-link class="header-logo-img gr-14 push-4 push-2@small" :to="{ name: 'home' }">
                                <img src="../../images/logo.svg" alt="Starkes Mindset">
                            </router-link>
                        </div>
                    </div>
                    <div class="header-menu gr-8">
                    </div>

                    <div class="header-title gr-6 push-1 push-0@desktop">
                        <img class="push-1" src="../../images/label.svg" alt="Starkes Mindset">
                    </div>
                </div>
            </div>
        </div>

        <div class="header-mobile">
            <div class="row">
                <div class="gr-24">
                    <div class="header-title gr-8@small gr-20@mobile push-16@small push-3@mobile">
                        <img class="push-1@small push-0@mobile" src="../../images/label.svg" alt="Starkes Mindset">
                    </div>
                </div>
            </div>
            <!--div class="row">
                <div class="gr-24">
                    <div class="header-menu gr-8@small gr-11@tablet gr-24@mobile push-14@small push-12@tablet push-0@mobile">
                        <base-button class="button gr-14@small gr-16@mobile gr-15@mini push-6@mobile push-4@mini" v-if="productPage"><a href="#signup">TEILNEHMEN</a></base-button>
                    </div>
                </div>
            </div-->
        </div>

    </header>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        computed: {
            productPage() {
                return this.$route.name === 'course'? true : false;
            }
        }
    }
</script>

<style scoped>
</style>

