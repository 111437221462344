<template>
    <div><div class="row">
        <h1 class="push-2 gr-24">Datenschutz
        </h1>
    </div>

        <div class="row">
            <div class="gr-24">
                <div class="text gr-20 push-2">
                    <p>Stand: 20.03.2019</p>
                    <p>
                        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.
                    </p>

                    <br/>
                    <h3>DATENSCHUTZ AUF EINEM BLICK</h3>
                    <h3>Allgemeine Hinweise</h3>
                    <p>Die folgenden Hinweise geben einen einfachen Überblick darüber,
                        was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere
                        Website besuchen. Personenbezogene Daten sind alle Daten, mit denen
                        Sie persönlich identifiziert werden können. Ausführliche Informationen
                        zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                        aufgeführten Datenschutzerklärung.</p>

                    <h3>Datenerfassung auf unserer Website</h3>
                    <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
                    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
                        Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
                    <br/>
                    <h4>Wie erfassen wir Ihre Daten?</h4>
                    <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
                        Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
                        eingeben.</p>
                    <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme
                        erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
                        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten
                        erfolgt automatisch, sobald Sie unsere Website betreten.</p>
                    <br/>
                    <h4>Wofür nutzen wir Ihre Daten?</h4>
                    <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
                        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
                        Nutzerverhaltens verwendet werden.</p>
                    <br/>
                    <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                    <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger
                        und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
                        außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten
                        zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
                        Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                        Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
                        Aufsichtsbehörde zu.</p>

                    <br/>
                    <h3>ALLGEMEINE HINWEISE UND PFLICHTINFORMATIONEN</h3>

                    <h3>Kontakt mit uns</h3>
                    <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>

                    <h3>Richtigstellung und Löschung</h3>
                    <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Kontaktieren Sie uns dazu bitte über folgende E-Mail-Adresse: <a href="mailto:hallo@starkes-mindset.com">hallo@starkes-mindset.com</a>.<br/>
                        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.</p>

                    <h3>Datenschutz</h3>
                    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
                        Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
                        gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                    <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
                        erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
                        identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert,
                        welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und
                        zu welchem Zweck das geschieht.</p>
                    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                        Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
                        Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>

                    <h3>Widerspruch gegen Werbe-Mails</h3>
                    <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
                        zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                        Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
                        Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                        Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

                    <br/>
                    <h3>DATENERFASSUNG AUF UNSERER WEBSITE</h3>
                    <h3>Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</h3>
                    <p>Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen
                        der Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung
                        beauftragte Kreditinstitut.</p>
                    <p>Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn
                        Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer
                        Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung,
                        erfolgt nicht.</p>
                    <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die
                        Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
                        Maßnahmen gestattet.</p>

                    <br/>
                    <h3>COOKIES</h3>
                    <p>
                        Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die
                        mit Hilfe des
                        Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies
                        dazu, unser
                        Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert,
                        bis Sie diese
                        löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                        Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das
                        Setzen von
                        Cookies informiert und Sie dies nur im Einzelfall erlauben.
                        Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.

                    </p>
                    <h3>Verwaltung von Cookies in Ihrem Browser</h3>
                    <p>
                        Nachfolgend finden Sie Links zu Anleitungen, wie Sie in gängigen Browsern Cookies verwalten und
                        löschen
                        können:
                    </p>
                    <ul>
                        <li>
                            <a href="https://support.google.com/chrome/answer/95647?hl=de&amp;ref_topic=14666">Chrome</a>
                        </li>
                        <li><a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">Firefox</a></li>
                        <li>
                            <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">Internet
                                Explorer</a>
                        </li>
                        <li><a href="https://support.apple.com/kb/Ph31411?viewlocale=de_DE&amp;locale=de_DE">Safari</a>
                        </li>
                        <li><a href="http://www.opera.com/help/tutorials/security/privacy/">Opera</a></li>
                    </ul>

                    <br/>
                    <h3>PLUGINS VON DRITTANBIETERN</h3>
                    <!-- <h3>Google Maps</h3>
                    <p>
                        Unsere Webseite bindet Karten von Google Maps ein. Ein Dienst von Google Inc. Google Maps
                        verwendet Cookies, um Informationen über die Nutzung des Dienstes zu sammeln. Die für Google
                        Maps geltende Datenschutzerklärung finden Sie <a href="https://www.google.de/intl/de/policies/privacy/">hier</a>.
                    </p> -->
                    <h3>Adobe Fonts</h3>
                    <p>
                        Unsere Webseite nutzt Web Fonts von Adobe Fonts. Ein Dienst von Adobe. Die für Adobe Fonts geltende Datenschutzerklärung finden Sie
                        <a href="https://www.adobe.com/at/privacy/policies/adobe-fonts.html">hier</a>.
                    </p>
                    <h3>Spotify</h3>
                    <p>Wir verwenden auf unserer Website Spotify, ein Tool für Musik- und Podcasts. Dienstanbieter ist das schwedische Unternehmen Spotify AB, Regeringsgatan 19, SE-111 53 Stockholm, Schweden. Mehr über die Daten, die durch die Verwendung von Spotify verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://www.spotify.com/de/legal/privacy-policy/">https://www.spotify.com/de/legal/privacy-policy/.</a></p>
                    <h3>Vimeo</h3>
                    <p>Wir verwenden auf unserer Website auch Videos der Firma Vimeo. Betrieben wird das Videoportal durch Vimeo LLC, 555 West 18th Street, New York, New York 10011, USA. Mit Hilfe eines Plug-ins können wir Ihnen so interessantes Videomaterial direkt auf unserer Website anzeigen. Dabei können gewissen Daten von Ihnen an Vimeo übertragen werden. In dieser Datenschutzerklärung zeigen wir Ihnen, um welche Daten es sich handelt, warum wir Vimeo verwenden und wie Sie Ihre Daten bzw. die Datenübertragung verwalten oder unterbinden können.<br/>
                        Vimeo ist eine Videoplattform, die 2004 gegründet wurde und seit 2007 das Streamen von Videos in HD-Qualität ermöglicht. Seit 2015 kann auch in 4k Ultra HD gestreamt werden. Die Nutzung des Portals ist kostenlos, es kann allerdings auch kostenpflichtiger Content veröffentlicht werden. Im Vergleich zum Marktführer YouTube, legt Vimeo vorrangig Wert auf hochwertigen Content in guter Qualität. So bietet das Portal einerseits viele künstlerische Inhalte wie Musikvideos und Kurzfilme, andererseits aber auch wissenswerte Dokumentationen zu den unterschiedlichsten Themen.
                    </p>
                    <p>Die für Vimeo geltende Datenschutzerklärung finden Sie <a href="https://vimeo.com/privacy">hier</a>.</p>
                    <h3>Digistore24</h3>
                    <p>Wir nutzen die Online-Verkaufsplattform Digistore24. Dienstanbieter ist das deutsche Unternehmen Digistore24 GmbH, St.-Godehard-Straße 32, 31139 Hildesheim, Deutschland. Mehr über die Daten, die durch die Verwendung von Digistore24 verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf <a href="https://www.digistore24.com/page/privacy.">https://www.digistore24.com/page/privacy.</a></p>
                    <h3>Sendinblue</h3>
                    <p>Sie können sich auf unserer Website kostenlos für unseren Newsletter anmelden. Damit das auch funktioniert, verwenden wir für unsere Newsletter den E-Mail-Versanddienst Sendinblue. Das ist ein Service des deutschen Unternehmens Sendinblue GmbH, Köpenicker Str. 126, 10179 Berlin. Sendinblue ist unter anderem ein E-Mail-Marketing-Tool, mit dem wir Ihnen maßgeschneiderte Newsletter schicken können. Mit Sendinblue müssen wir nichts installieren und können trotzdem aus einem Pool an wirklich nützlichen Funktionen schöpfen. Weitere wichtige datenschutzrelevante Aspekte zu Sendinblue finden Sie unter <a href="https://de.sendinblue.com/datenschutz-uebersicht/">https://de.sendinblue.com/datenschutz-uebersicht/</a>.</p>
                    <br/>
                    <h3>WEB-ANALYSE</h3>
                    <p>
                        Unsere Website benutzt Google Analytics. Ein Webanalysedienst von Google Inc. Dazu werden
                        Cookies verwendet,
                        die eine Analyse der Benutzung der Website durch Ihre Benutzer ermöglicht. Wir haben mit dem
                        Anbieter einen
                        entsprechenden Vertrag zur Auftragsdatenverarbeitung abgeschlossen. Die durch den Cookie
                        erzeugten
                        Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google
                        in den USA
                        übertragen und dort gespeichert.&nbsp;
                        Sie können das verhindern, indem Sie Ihren Browser so einrichten, dass keine Cookies gespeichert
                        werden.
                        Unser Anliegen im Sinne der DSGVO (berechtigtes Interesse) ist die Verbesserung unseres
                        Angebotes und
                        unseres Webauftritts. Da uns die Privatsphäre unserer Nutzer wichtig ist, werden die Nutzerdaten
                        pseudonymisiert.&nbsp;Dadurch ist nur mehr eine grobe Lokalisierung möglich.&nbsp;Die
                        Nutzerdaten werden für die Dauer
                        von 26 Monaten&nbsp;aufbewahrt.
                    </p>
                    <p>
                        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
                        Datenschutzerklärung
                        von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de">Datenschutzerklärung
                        von Google</a>
                    </p>

                    <!--br/>
                    <h3>NEWSLETTER</h3>
                    <h3>Klick-Tipp</h3>
                    <p>Diese Website nutzt Klick-Tipp für den Versand von Newslettern. Anbieter ist die
                        KLICK-TIPP LIMITED, 15 Cambridge Court, 210 Shepherd’s Bush Road, London W6 7NJ,
                        Vereinigtes Königreich.</p>
                    <p>Klick-Tipp ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert
                        und analysiert werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs
                        eingegeben Daten werden auf den Servern von Klick-Tipp gespeichert.</p>
                    <h4>Datenanalyse durch Klick-Tipp</h4>
                    <p>Wenn wir Newsletter mit Hilfe von Klick-Tipp versenden, können wir feststellen,
                        ob eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden.</p>
                    <p>Klick-Tipp ermöglicht es uns auch, die Newsletter-Empfänger anhand verschiedener
                        Kategorien zu unterteilen (sog. Tagging). Dabei lassen sich die Newsletterempfänger
                        z.B. nach Geschlecht, persönlichen Vorlieben (z.B. Vegetarier oder Nicht-Vegetarier)
                        oder Kundenbeziehung (z.B. Kunde oder potenzieller Kunde) unterteilen. Auf diese Weise
                        lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen.
                        Nähere Informationen erhalten Sie unter: <a href="https://www.klick-tipp.com" target="blank">https://www.klick-tipp.com</a> und
                        <a href="https://www.klick-tipp.com/handbuch" target="blank">https://www.klick-tipp.com/handbuch</a>.</p>
                    <p>Wenn Sie keine Analyse durch Klick-Tipp wollen, müssen Sie daher den Newsletter
                        abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden
                        Link zur Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf der
                        Website abbestellen.</p>
                    <h4>Rechtsgrundlage</h4>
                    <p>Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs.
                        1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Die
                        Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
                        Widerruf unberührt.</p>
                    <h4>Speicherdauer</h4>
                    <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden
                        von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der
                        Abbestellung des Newsletters sowohl von unseren Servern als auch von den Servern
                        von Klick-Tipp gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden
                        (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.</p>
                    <p>Näheres entnehmen Sie den Datenschutzbestimmungen von Klick-Tipp unter:
                        <a href="https://www.klick-tipp.com/datenschutzerklaerung" target="blank">https://www.klick-tipp.com/datenschutzerklaerung</a>.</p>
                    <h4>Abschluss eines Vertrags über Auftragsdatenverarbeitung</h4>
                    <p>Wir haben einen Vertrag über Auftragsdatenverarbeitung mit Klick-Tipp
                        abgeschlossen, in dem wir Klick-Tipp verpflichten, die Daten unserer
                        Kunden zu schützen und sie nicht an Dritte weiterzugeben.</p-->


                    <!--h3>ONLINE MARKETING UND PARTNERPROGRAMME</h3>
                    <h3>Amazon Partnerprogramm</h3>
                    <p>Die Betreiber der Seiten nehmen am Amazon EU- Partnerprogramm teil. Auf unseren
                        Seiten werden durch Amazon Werbeanzeigen und Links zur Seite von Amazon.de
                        eingebunden, an denen wir über Werbekostenerstattung Geld verdienen können.
                        Amazon setzt dazu Cookies ein, um die Herkunft der Bestellungen nachvollziehen
                        zu können. Dadurch kann Amazon erkennen, dass Sie den Partnerlink auf unserer
                        Website geklickt haben.</p>
                    <p>Die Speicherung von “Amazon-Cookies” erfolgt auf Grundlage von Art. 6 lit. f
                        DSGVO. Der Websitebetreiber hat hieran ein berechtigtes Interesse, da nur
                        durch die Cookies die Höhe seiner Affiliate-Vergütung feststellbar ist.</p>
                    <p>Weitere Informationen zur Datennutzung durch Amazon erhalten Sie in der
                        Datenschutzerklärung von Amazon: <a href="https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401" target="blank"></a>.</p-->

                    <br/>
                    <h3>FRAGEN UND ANREGUNGEN</h3>
                    <p>Bei Fragen zum Datenschutz stehen wir Ihnen gerne zur Verfügung. Kontaktdaten und nähere Informationen zu unserem Unternehmen finden Sie im <a href="./impressum.html" target="blank">Impressum</a>.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        }
    }
</script>


<style scoped>

</style>
