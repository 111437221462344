import { createRouter, createWebHistory } from 'vue-router';
//import store from './store/index.js';

import LandingPage from './pages/LandingPage.vue';
import OrderPage from './pages/OrderPage.vue';
import PrivacyPage from './pages/PrivacyPage.vue';
import ImprintPage from './pages/ImprintPage.vue';
//import NotFoundPage from './pages/NotFoundPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'home', component: LandingPage },
        { path: '/challenge/:slug', name: 'course', component: OrderPage, props: true },
        { path: '/privacy', name: 'privacy', component: PrivacyPage },
        { path: '/imprint', name: 'imprint', component: ImprintPage },
        { path: '/:notFound(.*)', name: 'notFound', component: LandingPage }
        /*{ path: '/:notFound(.*)', name: 'notFound', component: NotFoundPage }*/

    ],
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition;
        }
        return {
            top: 0,
            left: 0,
        }
    }
})

export default router;
